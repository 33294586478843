import {createContext, useContext, useEffect, useRef, useState} from "react";
import * as React from "react";
import {IDeviceArchitechture} from "../types/common";

type Props = {
    deviceArchitectureMapping: IDeviceArchitechture
}

type CommonContextType = {
    downloadButtonState: DownloadButtonState,
    setDownloadButtonState: Function,
    countdown: number | null,
    startCountdown: Function,
    clickApkUrl: string,
    setClickApkUrl: Function,
} & Props

export enum DownloadButtonState {
    Initial,
    DownloadClicked,
    DownloadStart,
    Downloading,
    DownloadAgain
}

const commonContextDefault : CommonContextType = {
    downloadButtonState: DownloadButtonState.Initial,
    setDownloadButtonState: () => {},
    countdown: null,
    startCountdown: () => {},
    deviceArchitectureMapping: {},
    clickApkUrl: "",
    setClickApkUrl: () => {}
}

export const CommonContextData = createContext<CommonContextType>(commonContextDefault)

export const useDownloadContext = () => {
  return useContext(CommonContextData)
}

export const CommonContext = (props: React.PropsWithChildren<Props>) => {
    const [downloadButtonState, setDownloadButtonState] = useState<DownloadButtonState>(DownloadButtonState.Initial)
    const [countdown, setCountdown] = useState<number | null>(null)
    const [clickApkUrl, setClickApkUrl] = useState<string>("")
    const deviceArchitectureMapping = props.deviceArchitectureMapping
    let timeout = useRef<NodeJS.Timeout | undefined>()

    useEffect(() => {
        downloadButtonState === DownloadButtonState.Downloading && clearInterval(timeout.current)
    }, [downloadButtonState]);

    const startCountdown = () => {
        let counterFromNumber: number = 5

        setCountdown(counterFromNumber)
        timeout.current = setInterval(() => {
            if (counterFromNumber >= 0) {
                --counterFromNumber
                setCountdown(counterFromNumber)
            } else {
                timeout && clearInterval(timeout.current)
            }
        }, 1000)
    }

    const value = {
        downloadButtonState,
        setDownloadButtonState,
        countdown,
        startCountdown,
        deviceArchitectureMapping,
        clickApkUrl,
        setClickApkUrl
    }

    return (
        <CommonContextData.Provider value={value}>
            {props.children}
        </CommonContextData.Provider>
    )
}